.App {
  text-align: center;
}

header {
  width: 100%;
  background-color: gainsboro;
  align-items: center;
}

a {
  text-decoration: none;
  color: #555555;
}

a:hover {
  text-decoration: underline;
}

li {
  margin-left: -25px;
}

.Title {
  font-size: 24px;
  margin-right: auto;
}

.Header {
  font-size: 24px;
}

.Menu-group {
  display: flex;
  width: 100%;
  justify-content: right;
  align-items: right;
}
 
.Menu-item {
  display: inline-block;
  padding: 0px 10px 0px 10px;
}

.Vote-player {
  padding: 15px;
}

.Vote-button {
  background-color: ghostwhite;
  border: solid 1px #999;
  padding: 10px;
}

.Vote-button:hover {
  background-color: whitesmoke;
}

.Stat-line {
  display: flex;
}

.Stat-cell {
  display: flex;
  border: solid 1px #999;
  width: 65px;
  height: 20px;
  justify-content: center;
  align-items: center;
}

.Stat-year {
  height: 64px;
}

.Stat-header {
  width: 266px;
  border: solid 1px #999;
  background-color: ghostwhite;
}

.Navigator {
  padding: 10px;
}

.Search-box {
  padding: 10px;
}

.Ranking {
  width: 100%;
}

.Ranking-line {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 370px;
  margin: auto;
}

.Ranking-line:nth-child(2n+1) {
  background-color: whitesmoke;
}

.Ranking-small-cell {
  width: 60px;
}

.Ranking-cell {
  width: 250px;
}

.Player-header {
  font-weight: bold;
}

.Player {
  width: 100%;
}

.Player-line {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  margin: auto;
}

.Player-line:nth-child(2n+1) {
  background-color: whitesmoke;
}

.Player-cell {
  width: 70px;
  font-size: 11px;
}

.Description {
  width: 85%;
  margin: auto;
  text-align: left;
}

button {
  border-radius: 30px;
  border: solid 1px #999;
}

.Credit {
  font-size: 12px;
}

.Link {
  padding-left: 5px;
  padding-right:5px;
}